
 .header-action {
     padding: 6px 12px;
     border-radius: 12px;
     background: var(--global-primary-background);
     color: var(--global-background);
     cursor: pointer;
     transition: 150ms ease;

     &:hover {
         opacity: 0.8;
     }
 }

 .expert-commission-summary {
     font-size : var(--global-font-size);
 }
